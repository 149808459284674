/* #sidebar {
    background-color: #31b372 !important;
} */

#sidebar {
    font-size: 1.3em !important;
    
}


